/* (C) Copyright 2020 MediaWink, LLC */

.spinner {
    animation: rotation 2s infinite linear;
    position: relative;
    z-index: 100;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }